import fetchServerResponse from "../helpers/serverHelper";

// lib/fetchConfig.js
export async function fetchConfig(host) {
    try {
        const headers = { 'x-origin': host, "saas-domain": host };

        const appConfigResponse = await fetchServerResponse({
            url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/pre_fetch/app_config`,
            method: 'GET',
            headers,
        });
        return JSON.parse(appConfigResponse?.data?.data?.app_config || '{}')

    } catch (error) {
        return {}
    }
}
// const backUp = {
//     "APP_NAME": "Stiiizy",
//     "APP_URL": "https://stiiizy.omnigreen.ai/",
//     "TERMS": "https://stiiizy.omnigreen.ai/termsandconditions",
//     "PRIVACY": "https://stiiizy.omnigreen.ai/privacypolicy",
//     "TWITTER": "https://x.com/stiiizy",
//     "FACEBOOK": "https://www.facebook.com/stiiizy/",
//     "INSTAGRAM": "https://www.instagram.com/stiiizy/",
//     "LINKEDIN": "https://www.linkedin.com/company/stiiizy/",
//     "YOUTUBE": "https://www.youtube.com",
//     "FAQ_URL": "https://stiiizy.omnigreen.ai/support/solutions",
//     "HEADER_LOGO_SHORT": "https://omnigreen-public-data.s3.us-west-2.amazonaws.com/store.omnigreen.ai/web_static_files/stiiizy-logo.png",
//     "HEADER_LOGO_COLOR": "https://omnigreen-public-data.s3.us-west-2.amazonaws.com/store.omnigreen.ai/web_static_files/stiiizy-logo.png",
//     "HEADER_LOGO_WHITE": "https://omnigreen-public-data.s3.us-west-2.amazonaws.com/store.omnigreen.ai/web_static_files/stiiizy-white.png",
//     "LOGO": "https://omnigreen-public-data.s3.us-west-2.amazonaws.com/store.omnigreen.ai/web_static_files/stiiizy-logo.png",
//     "LOGO_WHITE": "https://omnigreen-public-data.s3.us-west-2.amazonaws.com/store.omnigreen.ai/web_static_files/stiiizy-logo.png",
//     "LOGO_COLOR": "https://omnigreen-public-data.s3.us-west-2.amazonaws.com/store.omnigreen.ai/web_static_files/stiiizy-logo.png",
//     "LIVE_CHAT_IMAGE": "https://static-images.flowerandedibles.com/flowerandedibles.com/web_static_files/livechat64d454483915f32afdf7becd3ed78d7b.png",
//     "AGE_21": "https://static-images.flowerandedibles.com/flowerandedibles.com/web_static_files/age21_grassdoor_21.png",
//     "OFFER_PLACEHOLDER": "https://static-images.flowerandedibles.com/flowerandedibles.com/web_static_files/specialoffer.png",
//     "THIRD_PARTY_MEDICAL_CARD": true,
//     "FAVICON_URL": "https://omnigreen-public-data.s3.us-west-2.amazonaws.com/store.omnigreen.ai/web_static_files/stiiizy-logo.png",
//     "MANIFEST_URL": "https://static-images.flowerandedibles.com/flowerandedibles.com/web_static_files/manifest/manifest.json",
//     "PRODUCT_PLACEHOLDER_IMG": "https://static-images.flowerandedibles.com/flowerandedibles.com/web_static_files/default_product.png",
//     "PRIMARY": "#6DA738",
//     "LIGHT_PRIMARY": "#D0E8BA",
//     "DARK_PRIMARY": "#34501B",
//     "SECONDARY": "#000000",
//     "HEADLINE": "STIIIZY | Cannabis Flower, Extracts, Vapes, Pods & Weed Delivery",
//     "META_IMAGE": "https://omnigreen-public-data.s3.us-west-2.amazonaws.com/store.omnigreen.ai/web_static_files/stiiizy-logo.png",
//     "META_TITLE": "STIIIZY | Cannabis Flower, Extracts, Vapes, Pods & Weed Delivery",
//     "META_DESCRIPTION": "STIIIZY is committed to supplying premium quality cannabis products. Get yours today and stay STIIIZY.",
//     "META_TITLE_DESCRIPTION": "STIIIZY | Cannabis Flower, Extracts, Vapes, Pods & Weed Delivery",
//     "HIDE_PRODUCT_WEIGHT": false,
//     "HIDE_PRODUCT_PROPERTIES": false,
//     "EMAIL_VERIFICATION_FLAG": true,
//     "EXCHANGE_POLICY": "<p class='four-rem-pb'>Omnigreen will consider exchanging damaged or defective cannabis products if they are reported to <a href='mailto:support@flowerandedibles.com'>support@flowerandedibles.com </a>within 24 hours of purchase. All non-damaged cannabis product sales are final. We do not exchange products when customers do not like the look, feel, taste, smell or any other characteristic of the product. We only consider exchanges when a hardware product, such as a battery, pod or cart are damaged or defective. All exchanges shall be at the discretion of Flowers & Edibles. Refunds are not permitted.</p><br/><br/>",
//     "SALE_TAG_NAME": "SALE",
//     "SUPPORT_CONTACT": "+1 (213) 816-7222",
//     "SUPPORT_EMAIL": "delivery@stiiizy.com",
//     "DISPLAY_TAXED_PRICE": true,
//     "BANNER_HEIGHT": 700.8,
//     "BANNER_WIDTH": 1920,
//     "BANNER_HEIGHT_MOBILE": 752.4,
//     "BANNER_WIDTH_MOBILE": 600,
//     "BRANCH_IO_KEY": "key_live_hrp6bZQp945b3sNajM1vaafmAuahcD6c",

//     "SEARCH_INDICE": "stiiizy_prod",
//     "SEARCH_KEY": "7da8c4f5394f00b90edc7d9277b773cd",
//     "SEARCH_CONTAINER": "MPLRSXA69A",
//     "SEARCH_ADMIN_KEY": "d43cc5edc9667c2db06cf7dcff239fc4",
//     "STRONGHOLD_PUBLISHABLE_KEY": "pk_live_YjllZDlkNjQtYzdkNC00NmJm",
//     "STRONGHOLD_PUBLISHABLE_KEY_LA": "pk_live_YjllZDlkNjQtYzdkNC00NmJm",
//     "STRONGHOLD_PUBLISHABLE_KEY_SF": "pk_live_YWMzZTNmZGUtYWU3Zi00ZjNm",
//     "STRONGHOLD_INTEGRATION_KEY": "integration_1PYL1uwwlCBThylS6P1IW70R",
//     "GTAG_KEY": "G-JHZX1LQJK2",
//     "GTM_KEY": "GTM-MWNQK8XF"
// }