// lib/withConfig.js
import { fetchConfig } from './fetchConfig';

export function withConfig(gssp) {
    return async (context) => {
        const host = context?.req?.headers?.host === 'localhost:3000'
            ? process.env.NEXT_PUBLIC_FALLBACK_API_URL
            : context?.req?.headers?.host;
        const configData = await fetchConfig(host);

        const result = await gssp(context);
        return {
            ...result,
            props: {
                ...result.props,
                config: { ...configData },
            },
        };
    };
}
